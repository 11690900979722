import React, { useRef, useState, useEffect } from 'react';
import { Upload, VideoOff } from 'lucide-react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const TextField = ({ id, label, sublabel=null, placeholder, disabled=false, value, onChange, margin=true }) => (
    <div className={margin?'mb-4':''} id={`field-text-${id}`}>
      <label htmlFor={`text-${label}`} className="block text-sm font-medium mb-1">{label}</label>
      {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
      <input 
        id={`text-${label}`}
        placeholder={placeholder} 
        className="w-full p-2 border rounded text-xs sm:text-base" 
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </div>
);

const IntField = ({ id, label, sublabel=null, placeholder, disabled=false, value, setValue, margin=true }) => {
    const [isValid, setIsValid] = useState(true);
    const [internalValue, setInternalValue] = useState(value);
  
    const onChange = (event) => {
      // try to parse int, and show yellow if invalid
      let newValue = parseInt(event.target.value);
      if (isNaN(newValue)) {
        setIsValid(false);
        setValue(null);
      } else {
        setIsValid(true);
        setValue(newValue);
      }

      setInternalValue(event.target.value);
    }

    return (
        <div className={margin?'mb-4':''} id={`field-int-${id}`}>
            <label htmlFor={`text-${label}`} className="block text-sm font-medium mb-1">{label}</label>
            {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
            <input 
                id={`text-${label}`}
                placeholder={placeholder} 
                className={`w-full p-2 rounded ${isValid?'border':'border-1 border-[#D70000]'} `}
                value={internalValue}
                disabled={disabled}
                onChange={onChange}
            />
        </div>
    );
}

const FloatField = ({ id, label, sublabel=null, placeholder, disabled=false, value, setValue, margin=true }) => {
    const [isValid, setIsValid] = useState(true);
    const [internalValue, setInternalValue] = useState(value);

    const onChange = (event) => {
        // try to parse int, and show yellow if invalid
        let newValue = parseFloat(event.target.value);
        if (isNaN(newValue)) {
          setIsValid(false);
          setValue(null);
        } else {
          setIsValid(true);
          setValue(newValue);
        }
  
        setInternalValue(event.target.value);
      }

    return (
        <div className={margin?'mb-4':''} id={`field-float-${id}`}>
        <label htmlFor={`text-${label}`} className="block text-sm font-medium mb-1">{label}</label>
        {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
        <input 
            id={`text-${label}`}
            placeholder={placeholder} 
            className={`w-full p-2 rounded ${isValid?'border':'border-1 border-[#D70000]'} `}
            disabled={disabled}
            value={internalValue}
            onChange={onChange}
        />
        </div>
    );
}

const BoolField = ({ id, label, sublabel=null, placeholder, disabled=false, value, setValue, margin=true }) => {
    const onChange = (event) => {
        setValue(event.target.checked);
    }

    return (
        <div className={`flex flex-col ${margin?'mb-4':''} items-start`} id={`field-bool-${id}`}>
            <label htmlFor={`text-${label}`} className="block text-sm font-medium mb-1">{label}</label>
            {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
            <input type="checkbox" placeholder={placeholder}  className="m-0 p-2 border rounded"  id={`text-${label.replaceAll(' ','-')}`} disabled={disabled} checked={value} onChange={onChange} />
        </div>
    );
}

const BoolField2 = ({ id, label, sublabel=null, placeholder, disabled=false, value, setValue }) => {
    const onChange = (event) => {
        setValue(event.target.checked);
    }

    return (
        <div className="flex flex-row mb-0 items-center space-x-3" id={`field-bool-${id}`}>
            <input type="checkbox" placeholder={placeholder} className="m-0 p-2 border rounded border-black bg-white accent-white checked:border-2 checked:border-black cursor-pointer"
              id={`text-${label.replaceAll(' ','-')}`} disabled={disabled} checked={value} onChange={onChange} />
            <label htmlFor={`text-${label}`} className="block text-sm font-light mb-1">{label}</label>
            {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
        </div>
    );
}

const DateField = ({ id, label, sublabel=null, placeholder, disabled=false, value, setValue, margin=true }) => {
    const onChange = (date) => {
        console.log(date)
        setValue(new Date(date));
      }

    return (
        <div className={margin?'mb-4':''} id={`field-date-${id}`}>
            <label htmlFor={`text-${label}`} className="block text-sm font-medium mb-1">{label}</label>
            {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
            <div className="w-full p-2 border rounded text-xs sm:text-base">
                <DatePicker selected={value ? new Date(value) : new Date(0)} showTimeSelect dateFormat="Pp" disabled={disabled} onChange={onChange} />
            </div>
        </div>
    );
}

const TimeField = ({ id, label, sublabel=null, placeholder, disabled=false, value, setValue, margin=true }) => {
    const onChange = (time) => {
        console.log(time)
        setValue(new Date(time));
      }

    return (
        <div className={margin?'mb-4':''} id={`field-date-${id}`}>
            <label htmlFor={`text-${label}`} className="block text-sm font-medium mb-1">{label}</label>
            {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
            <div className="w-full p-2 border rounded text-xs sm:text-base">
                <DatePicker 
                    selected={value ? new Date(value) : new Date(0)} 
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    disabled={disabled} 
                    onChange={onChange} 
            />
            </div>
        </div>
    );
}

const EnumField = ({ id, label, sublabel=null, info, placeholder, disabled=false, value, setValue, values, margin=true }) => {
    const onChange = (value) => {
        setValue(value);
    }

    return (
        <div className={`flex flex-col p-2 ${margin?'mb-4':''} border rounded`} id={`field-enum-${id}`}>
            <div className="flex flex-row justify-start items-center space-x-2 mx-2 py-2 mb-4 border-b border-[#E4E7EC]">
                <label className="my-auto block text-sm font-medium">{label}</label>
                {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
                <p className="my-auto text-xs text-gray-400">{info}</p>
            </div>
            <div className="flex flex-wrap text-xs">
                {values?.map(option => (
                    <span className={`m-1 px-[.25rem] py-[.25rem] sm:px-4 sm:py-2.5 bg-white rounded cursor-pointer ${(value === option && !disabled) ? 'bg-gradient-to-br from-gradient-blue to-gradient-purple font-light text-white' : 'text-text-gray'}`} onClick={() => {
                        console.log('clicked', option);    
                        onChange(option);
                    }}>
                        {option}
                    </span>
                ))}
            </div>
        </div>
    );
}

const EnumField2 = ({ id, label, sublabel=null, info, placeholder, disabled=false, value, setValue, values, margin=true }) => {
    const onChange = (value) => {
        setValue(value);
    }

    return (
        <div className={`flex flex-col p-2 ${margin?'mb-4':''} border rounded`} id={`field-enum-${id}`}>
            <div className="flex flex-col py-2 mb-4 border-b border-[#E4E7EC]">
                <div className="flex flex-row justify-start items-center space-x-2 mx-2">
                    <label className="my-auto block text-sm font-medium">{label}</label>
                    <p className="my-auto text-xs text-gray-400">{info}</p>
                </div>
                {sublabel !== null && <label htmlFor={`text-${label}`} className="my-auto block text-xs font-xs mb-1 mx-2 text-text-black">{sublabel}</label>}
            </div>
            <div className="flex flex-wrap text-xs">
                {values?.map(option => (
                    <span className={`m-1 px-[.25rem] py-[.25rem] sm:px-4 sm:py-2.5 bg-white rounded cursor-pointer ${(value === option.value && !disabled) ? 'bg-gradient-to-br from-gradient-blue to-gradient-purple font-light text-white' : 'text-text-gray'}`} onClick={() => {
                        console.log('clicked', option.label, option.value);    
                        onChange(option.value);
                    }}>
                        {option.label}
                    </span>
                ))}
            </div>
        </div>
    );
}

const EnumField3 = ({ id, label, sublabel=null, info, placeholder, disabled=false, value, setValue, values, margin=true, list=false, scroll=false }) => {
    const onChange = (value) => {
        setValue(value);
    }

    return (
        <div className={`flex flex-col p-2 ${scroll ? 'h-full' : ''} ${margin?'mb-4':''} border rounded`} id={`field-enum-${id}`}>
            <div className="flex flex-col py-2 mb-4 border-b border-[#E4E7EC]">
                <div className="flex flex-row justify-start items-center space-x-2 mx-2">
                    <label className="my-auto block text-sm font-medium">{label}</label>
                    <p className="my-auto text-xs text-gray-400">{info}</p>
                </div>
                {sublabel !== null && <label htmlFor={`text-${label}`} className="my-auto block text-xs font-xs mb-1 mx-2 text-text-black">{sublabel}</label>}
                {value !== null && <label htmlFor={`text-${label}`} className="my-auto block text-xs font-xs mb-1 mx-2 text-text-black">{values.find(v => v.value === value).description}</label>}
            </div>
            <div className={`flex flex-wrap text-xs ${scroll ? 'h-full overflow-y-scroll' : ''}`}>
                {values?.map(option => (
                    <span className={`m-1 px-[.25rem] py-[.25rem] sm:px-4 sm:py-2.5 bg-white rounded cursor-pointer ${(value === option.value && !disabled) ? 'bg-gradient-to-br from-gradient-blue to-gradient-purple font-light text-white' : 'text-text-gray'}${list ? ' w-full' : ''}`} onClick={() => {
                        console.log('clicked', option.label, option.value);    
                        onChange(option.value);
                    }}>
                        {option.label}
                    </span>
                ))}
            </div>
        </div>
    );
}

const EnumDropdown = ({ id, label, sublabel=null, info, placeholder, disabled=false, value, setValue, values, margin=true }) => {
    const onChange = (value) => {
        setValue(value);
    }

    return (
        <div className={`flex flex-col p-2 ${margin?'mb-4':''} border rounded`} id={`field-enum-${id}`}>
            <div className="flex flex-row justify-start items-center space-x-2 mx-2 py-2 mb-4 border-b border-[#E4E7EC]">
                <label className="my-auto block text-sm font-medium">{label}</label>
                {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
                <p className="my-auto text-xs text-gray-400">{info}</p>
            </div>
            <div className="flex flex-wrap text-xs">
                <select
                    className="m-1 px-[.25rem] py-[.25rem] sm:px-4 sm:py-2.5 bg-white rounded cursor-pointer text-text-gray"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                >
                    {values?.map(option => (
                        <option value={option.value}>{option.label}</option>
                    ))}
                </select>
            </div>
        </div>
    );
}

const MultiEnumField = ({ id, label, sublabel=null, info, placeholder, disabled=false, value, setValue, values, margin=true }) => {
    const onChange = (newValue) => {
        if(value === undefined) {
            setValue([newValue]);
        } else {
            if (value.includes(newValue)) {
                setValue(value.filter(v => v !== newValue));
            } else {
                setValue([...value, newValue]);
            }
        }
    }

    return (
        <div className={`flex flex-col p-2 ${margin?'mb-4':''} border rounded`} id={`field-multi-enum-${id}`}>
            <div className="flex flex-row justify-start items-center space-x-2 mx-2 py-2 mb-4 border-b border-[#E4E7EC]">
                <label className="my-auto block text-sm font-medium">{label}</label>
                {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
                <p className="my-auto text-xs text-gray-400">{info}</p>
            </div>
            <div className="flex flex-wrap text-xs">
                {values?.map(option => (
                    <span 
                        className={`m-1 px-[.25rem] py-[.25rem] sm:px-4 sm:py-2.5 bg-white rounded cursor-pointer ${(value?.includes(option) && !disabled) ? 'bg-gradient-to-br from-gradient-blue to-gradient-purple font-light text-white' : 'text-text-gray'}`} 
                        onClick={() => {
                            console.log('clicked', option);    
                            onChange(option);
                        }}
                    >
                        {option}
                    </span>
                ))}
            </div>
        </div>
    );
}

const MultiEnumField2 = ({ id, label, sublabel=null, info, placeholder, disabled=false, value, setValue, values, margin=true }) => {
    const onChange = (newValue) => {
        if(value === undefined) {
            setValue([newValue]);
        } else {
            if (value.includes(newValue)) {
                setValue(value.filter(v => v !== newValue));
            } else {
                setValue([...value, newValue]);
            }
        }
    }

    return (
        <div className={`flex flex-col p-2 ${margin?'mb-4':''} border rounded`} id={`field-multi-enum-${id}`}>
            <div className="flex flex-col py-2 mb-4 border-b border-[#E4E7EC]">
                <div className="flex flex-row justify-start items-center space-x-2 mx-2">
                    <label className="my-auto block text-sm font-medium">{label}</label>
                    <p className="my-auto text-xs text-gray-400">{info}</p>
                </div>
                {sublabel !== null && <label htmlFor={`text-${label}`} className="my-auto block text-xs font-xs mb-1 mx-2 text-text-black">{sublabel}</label>}
            </div>

            <div className="flex flex-wrap text-xs">
                {values?.map(option => (
                    <span 
                        className={`m-1 px-[.25rem] py-[.25rem] sm:px-4 sm:py-2.5 bg-white rounded cursor-pointer ${(value?.includes(option.value) && !disabled) ? 'bg-gradient-to-br from-gradient-blue to-gradient-purple font-light text-white' : 'text-text-gray'}`} 
                        onClick={() => {
                            console.log('clicked', option);    
                            onChange(option.value);
                        }}
                    >
                        {option.label}
                    </span>
                ))}
            </div>
        </div>
    );
}

const DescriptionField = ({ id, label, sublabel=null, placeholder, copy=false, disabled=false, value, onChange, margin=true, fullheight=false}) => (
    <div className={`${margin ? 'mb-4':''} ${fullheight ? 'h-full pb-4':''}`} id={`field-description-${id}`}>
      <div className="flex flex-row justify-start items-center space-x-2">
        <label htmlFor={`description-${label}`} className="block text-sm font-medium mb-1">{label}</label>
        {copy && <button 
          className="text-xs hover:text-text-gray-dark border rounded px-1 my-0 mb-1"
          onClick={() => navigator.clipboard.writeText(placeholder)}
        >Copy Example</button>}
      </div>
      {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
      <textarea 
        id={`description-${label}`}
        placeholder={placeholder}
        className={`w-full p-2 border rounded h-32 ${fullheight ? 'h-full':''}`}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
);

const FileUploadField = ({ id, label, sublabel=null, description, info, alert, onChange, margin=true }) => {
    const fileInputRef = useRef(null);
    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className={margin?'mb-4':''} id={`field-file-${id}`}>
            <label className="block text-sm font-medium mb-1">{label}</label>
            {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
            <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer hover:bg-gray-50" onClick={handleDivClick}>
                <input 
                    type="file" 
                    ref={fileInputRef}
                    className="hidden"
                    onChange={onChange} 
                    required 
                />
                <Upload className="mx-auto mb-2 text-gray-400" size={24} />
                <p className="text-sm text-gray-600 mb-1">{description}</p>
                <p className="text-xs text-gray-400">{info}</p>
            </div>
            <p className="text-sm text-gray-600 mt-2">{alert}</p>
        </div>
    )
}

const FilesUploadField = ({ id, label, sublabel=null, description, info, alert, onChange, margin=true }) => {
    const fileInputRef = useRef(null);
    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div className={margin?'mb-4':''} id={`field-files-${id}`}>
            <label className="block text-sm font-medium mb-1">{label}</label>
            {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
            <div className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center cursor-pointer hover:bg-gray-50" onClick={handleDivClick}>
                <input 
                    type="file" 
                    multiple="multiple"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={onChange} 
                    required 
                />
                <Upload className="mx-auto mb-2 text-gray-400" size={24} />
                <p className="text-sm text-gray-600 mb-1">{description}</p>
                <p className="text-xs text-gray-400">{info}</p>
            </div>
            <p className="text-sm text-gray-600 mt-2">{alert}</p>
        </div>
    )
}

const Button = ({ id, type='basic', label, sublabel=null, disabled=false, onClick, disabledLabel='Loading...', css=null }) => {
    const styleMap = {
        'basic': `text-xs sm:text-base bg-black text-white px-4 py-2 rounded hover:bg-gray-800 transition-colors ${css ? ` ${css}` : ''}`,
        'secondary': `text-xs sm:text-base bg-white text-gray-700 border border-gray-300 hover:bg-gray-50 ${css ? ` ${css}` : ''}`,
        'wide': `text-xs sm:text-base w-full bg-black text-white py-2 px-4 rounded hover:bg-gray-800 transition-colors ${css ? ` ${css}` : ''}`,
        'wide-secondary': `text-xs sm:text-base w-full bg-white text-gray-700 py-2 px-4 rounded border border-gray-300 hover:bg-gray-50 transition-colors ${css ? ` ${css}` : ''}`,
        'wide-styled': `text-xs sm:text-base w-full py-2 px-4 rounded transition-colors text-text-secondary-light bg-gradient-to-br from-gradient-blue to-gradient-purple font-light ${css ? ` ${css}` : ''}`,
        'danger': `text-xs sm:text-base w-[10%] bg-danger text-white px-4 py-2 rounded transition-colors ${css ? ` ${css}` : ''}`,
        'wide-danger': `text-xs sm:text-base w-full bg-danger text-white px-4 py-2 rounded transition-colors ${css ? ` ${css}` : ''}`,
        'success': `text-xs sm:text-base w-[10%] bg-success text-white px-4 py-2 rounded transition-colors ${css ? ` ${css}` : ''}`,
        'wide-success': `text-xs sm:text-base w-full bg-success text-white px-4 py-2 rounded transition-colors ${css ? ` ${css}` : ''}`,
    };

    return (
        <button className={styleMap[type]} onClick={onClick} disabled={disabled} id={`button-${id}`}>
            {disabled ? disabledLabel : (label + (sublabel || ''))}
        </button>
    );
}

const Video = ({ id, label, sublabel=null, info, url, margin=true }) => {
    return (
        <div className={`flex flex-col p-2 ${margin?'mb-4':''} border rounded`} id={`field-video-${id}`}>
            {(label || info) && <div className="flex flex-row justify-start items-center space-x-2 mx-2 py-2 mb-4 border-b border-[#E4E7EC]" >
                <label className="my-auto block text-sm font-medium">{label}</label>
                {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
                <p className="my-auto text-xs text-gray-400">{info}</p>
            </div>}
            <div className="flex flex-wrap text-xs">
                <video controls src={url} type="video/mp4" >
                    <source src={url} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
}

const VideoImage = ({ id, label, sublabel=null, info, url, margin=true }) => {
    return (
        <div className={`flex flex-col p-2 ${margin?'mb-4':''} border rounded`} id={`field-video-${id}`}>
            {(label || info) && <div className="flex flex-row justify-start items-center space-x-2 mx-2 py-2 mb-4 border-b border-[#E4E7EC]" >
                <label className="my-auto block text-sm font-medium">{label}</label>
                {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
                <p className="my-auto text-xs text-gray-400">{info}</p>
            </div>}
            <div className="flex flex-wrap text-xs">
                <video src={`${url}#t=0.1`} type="video/mp4" >
                    <source src={`${url}#t=0.1`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    );
}

const Image = ({ id, label, sublabel=null, info, url, contain=false }) => {
    return (
        <div className={`h-full flex flex-col p-2 mb-0 border rounded ${contain ? 'overflow-hidden' : ''}`} id={`field-image-${id}`}>
            {(label || info) && <div className="flex flex-row justify-start items-center space-x-2 mx-2 py-2 mb-4 border-b border-[#E4E7EC]" >
                <label className="my-auto block text-sm font-medium">{label}</label>
                {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
                <p className="my-auto text-xs text-gray-400">{info}</p>
            </div>}
            <div className={`flex ${contain ? 'flex-row grow min-h-0' : 'flex-wrap'} text-xs ${contain ? 'h-full' : ''}`}>
                <img src={url} alt='img-field' className={`${contain ? 'mx-auto object-contain rounded-[30px]' : ''}`} />
            </div>
        </div>
    );
}

const ImageResponsive = ({ id, label, sublabel=null, info, url, contain=false }) => {
    return (
        <div className={`h-full flex flex-col p-2 mb-0 border rounded ${contain ? 'overflow-hidden' : ''}`} id={`field-image-${id}`}>
            {(label || info) && <div className="flex flex-row justify-start items-center space-x-2 mx-2 py-2 mb-4 border-b border-[#E4E7EC]" >
                <label className="my-auto block text-sm font-medium">{label}</label>
                {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
                <p className="my-auto text-xs text-gray-400">{info}</p>
            </div>}
            <div className={`flex ${contain ? 'flex-row grow min-h-0' : 'flex-wrap'} text-xs ${contain ? 'h-full' : ''}`}>
                <img src={url[0] ? url[0] : url[2]} alt='img-field-horizontal' className={`hidden sm:block ${contain ? 'mx-auto object-contain rounded-[30px]' : ''}`} />
                <img src={url[1] ? url[1] : url[2]} alt='img-field-vertical' className={`sm:hidden ${contain ? 'mx-auto object-contain rounded-[30px]' : ''}`} />
            </div>
        </div>
    );
}

const Audio = ({ id, label, sublabel=null, info, url }) => {
    return (
        <div className="flex flex-col p-2 mb-0 border rounded" id={`field-audio-${id}`}>
            {(label || info) && <div className="flex flex-row justify-start items-center space-x-2 mx-2 py-2 mb-4 border-b border-[#E4E7EC]" >
                <label className="my-auto block text-sm font-medium">{label}</label>
                {sublabel !== null && <label htmlFor={`text-${label}`} className="block text-xs font-xs mb-1">{sublabel}</label>}
                <p className="my-auto text-xs text-gray-400">{info}</p>
            </div>}
            <div className="flex flex-wrap text-xs">
                <audio controls src={url} type="audio/mpeg" >
                    <source src={url} type="audio/mpeg" />
                </audio>
            </div>
        </div>
    );
}

const SingleSelect = ({ id, label, sublabel=null, info, options, value, onChange, margin=true, render=null }) => {
    return (
        <div className={`flex flex-col p-2 ${margin?'mb-4':''} border rounded`} id={`field-single-select-${id}`}>
            <div className="flex flex-col py-2 mb-4 border-b border-[#E4E7EC]">
                <div className="flex flex-row justify-start items-center space-x-2 mx-2">
                    <label className="my-auto block text-sm font-medium">{label}</label>
                    <p className="my-auto text-xs text-gray-400">{info}</p>
                </div>
                {sublabel !== null && <label htmlFor={`text-${label}`} className="my-auto block text-xs font-xs mb-1 mx-2 text-text-black">{sublabel}</label>}
            </div>
            <div className="flex flex-wrap text-xs">
                {options?.map(option => (
                    <span className={`m-1 px-[.25rem] py-[.25rem] sm:px-4 sm:py-2.5 bg-white rounded cursor-pointer ${value === option.value ? 'bg-gradient-to-br from-gradient-blue to-gradient-purple font-light text-white' : 'text-text-gray'}`} onClick={() => {
                        console.log('clicked', option);    
                        onChange(option.value);
                    }}>
                        {option.label}
                    </span>
                ))}
            </div>
            {render && render(value)}
        </div>
    );
}

// returns a loading spinner with a progress bar that is updated by the progress prop
const Loader = ({ id, title, progress }) => {
    return (
        <div className="flex flex-col h-full p-2 border rounded" id={`field-loader-${id}`}>
            <div className="flex flex-row justify-start items-center space-x-2 mx-2 py-2 mb-4 border-b border-[#E4E7EC]" >
                <label className="my-auto block text-sm font-medium">{title}</label>
            </div>
            <div className="flex flex-col items-center">
                <div role="status">
                    <svg aria-hidden="true" class="w-10 h-10 text-light-purple animate-spin fill-dark-purple" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>
                
                <div className="w-full bg-gray-200 h-2 mt-2">
                    <div className="bg-gradient-to-br from-gradient-blue to-gradient-purple h-2" style={{width: `${progress*100}%`}}></div>
                </div>
            </div>
        </div>
    );
}

const all = {
    TextField,
    IntField,
    FloatField,
    BoolField,
    BoolField2,
    DateField,
    TimeField,
    EnumField,
    EnumField2,
    EnumField3,
    EnumDropdown,
    MultiEnumField,
    MultiEnumField2,
    DescriptionField,
    FileUploadField,
    FilesUploadField,
    Button,
    Video,
    VideoImage,
    Image,
    Audio,
    SingleSelect,
    Loader,
    ImageResponsive
};

export default all;
