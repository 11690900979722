import React, { useState, useRef, useEffect } from 'react';

import config from '../../config/config';
import axios from 'axios';
import globalConfig from '../../config/config';
import { getAuth, authHeader } from '../../utils/auth';

import Fields from '../common/Fields';
import NewView from "../common/NewView";
import OneOffAlert from './OneOffAlert';

const OneOffMain = ({backEvent, setBackEvent, setBackEnabled}) => {
  const S3_PATH_PREFIX = `https://cdn1.netizens.ai`

  const DEMOS = [
    {
      label: "Tia Podcast",
      value: 0,
      image_horizontal: S3_PATH_PREFIX + "/demo_scenes/podcast2.png",
      image_vertical: S3_PATH_PREFIX + "/demo_scenes/podcast2.png",
      script: "Hey, babes! Welcome back to Tia Show! You already know, it’s your girl, Tia Tessa Smith, bringing you all the good energy, real talk, and just a lil’ bit of tea. Grab your matcha, settle in, and let’s get into it—today’s topic is sooo juicy, you do not wanna miss this!",
      video: S3_PATH_PREFIX + "/demo_scenes/podcast2_small.mp4",
    },
    {
      label: "Just Chatting",
      value: 1,
      image_horizontal: S3_PATH_PREFIX + "/demo_scenes/podcast.png",
      image_vertical: S3_PATH_PREFIX + "/demo_scenes/podcast.png",
      script: "Hey, babes! Welcome back to Tia Show! You already know, it’s your girl, Tia Tessa Smith, bringing you all the good energy, real talk, and just a lil’ bit of tea. Grab your matcha, settle in, and let’s get into it—today’s topic is sooo juicy, you do not wanna miss this!",
      video: S3_PATH_PREFIX + "/demo_scenes/podcast_small.mp4",
    },
    // {
    //   label: "Selfie On The Bed",
    //   value: 2,
    //   image: "https://assets.superhivemarket.com/store/productimage/535338/image/f6c81950898fa3288e9a86045df48578.jpg",
    //   script: "Welcome fellow degenerates! I am a netizen created by the netizens.ai platform. What do you want me to post about?",
    //   video: "https://cdn1.netizens.ai/2bc72b6f43fb6fe31da92859413c4830.mp4",
    // },
    // {
    //   label: "Chilling on the Couch",
    //   value: 3,
    //   image: "https://assets.superhivemarket.com/store/productimage/535339/image/5c5be0ec895a8fdd0b3be8fc73907704.jpg",
    //   script: "Demo 2!!!!",
    //   video: "https://cdn1.netizens.ai/fafa138ce766623d981442896ccaa78e.mp4",
    // },
    // {
    //   label: "Podcast Conversations",
    //   value: 4,
    //   image: "https://assets.superhivemarket.com/store/productimage/535338/image/f6c81950898fa3288e9a86045df48578.jpg",
    //   script: "Welcome fellow degenerates! I am a netizen created by the netizens.ai platform. What do you want me to post about?",
    //   video: "https://cdn1.netizens.ai/2bc72b6f43fb6fe31da92859413c4830.mp4",
    // },
    // {
    //   label: "Recent News",
    //   value: 5,
    //   image: "https://assets.superhivemarket.com/store/productimage/535339/image/5c5be0ec895a8fdd0b3be8fc73907704.jpg",
    //   script: "Demo 2!!!!",
    //   video: "https://cdn1.netizens.ai/fafa138ce766623d981442896ccaa78e.mp4",
    // }
  ]

  const success_title = "Success!"
  const success_description = "Next, generate unlimited videos and styles with your own custom voice and avatar. Don't let other channels beat yours, the race is on!"

  const [style, setStyle] = useState(0);
  const [activeDemo, setActiveDemo] = useState(null);
  const [activeVideo, setActiveVideo] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  // after createEnabled is set to false, set it back to true after 5 seconds
  useEffect(() => {
    if (progress > 0 && progress < 1) {
      const timer = setTimeout(() => {
        let new_progress = Math.min(progress + 0.01, 1)

        setProgress(new_progress);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [progress]);

  // change alert state based on progress
  useEffect(() => {
    if (progress >= 1) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [progress]);

  // update active demo
  useEffect(() => {
    let _demo = DEMOS.find(d => d.value == style) // don't want strict equality here
    setActiveDemo(_demo)
  }, [style]);

  // update active video
  useEffect(() => {
    let _video = activeDemo ? activeDemo.video : ''
    setActiveVideo(_video)
  }, [activeDemo]);

  // handle creation
  const onClickCreate = async () => {
    setProgress(0.01);
  }

  // handle upgrade
  const onClickUpgrade = async () => {
    window.open('https://netizens.ai#plans', '_blank');
  }

  // handle close alert
  const onClickClose = async () => {
    setProgress(0);
  }

  // handle download alert
  const onClickDownload = async () => {
    // download video
    let url = activeDemo ? activeDemo.video : ''
    window.open(url, '_blank');
  }

  return (
    <NewView>
      <OneOffAlert
        title={success_title}
        description={success_description}
        url={activeVideo}
        visible={showAlert}
        onClickClose={onClickClose}
        onClickDownload={onClickDownload}
      />

      <div className="flex justify-between items-center mb-4 space-x-20 hidden md:block">
        <div className>
          <div className="flex items-center space-x-3">
            <h1 className="text-lg font-regular m-0 mr-2">
              Generate
            </h1>
          </div>
        </div>
      </div>
      
      <div className="flex flex-col justify-end h-full overflow-hidden">
        <div className="flex h-2/3 flex-row justify-between">
          <div className="flex w-2/3 flex-col sm:flex-row justify-between sm:space-x-10 pr-5 mb-4">
            <div className="flex flex-col grow w-full h-full min-h-0">
              {!(progress > 0 && progress < 1) && <Fields.ImageResponsive
                label="Netizen"
                sublabel=""
                info={""} 
                contain={true}
                url={activeDemo ? [activeDemo.image_horizontal, activeDemo.image_vertical, activeDemo.image] : ''}
              />}
              {(progress > 0 && progress < 1) && <Fields.Loader
                title="Loading..."
                progress={progress}
              />}
            </div>
          </div>

          <div className="flex w-1/3 flex-col gap-4 justify-between sm:space-x-10 pr-1 mb-4">
            <div className="w-full flex h-2/3 flex-col">
              <Fields.EnumField3
                label="Demo"
                sublabel=""
                info={""} 
                disabled={false}
                value={style} 
                setValue={
                  (value) => {
                    setStyle(value)
                  }} 
                values={DEMOS} 
                margin={false}
                list={true}
                scroll={true}
              />
            </div>

            <div className="flex w-full h-1/3 flex-col gap-1 justify-end !ml-0">
              <div className="flex flex-col !mb-0 !ml-0">
                <Fields.Button type='wide-styled' css='h-[60px]' label={'Create'} onClick={()=>onClickCreate()} id="demo-create-button" disabled={progress > 0 && progress < 1} />
              </div>

              <div className="flex flex-col !mb-0 !ml-0">
                <Fields.Button type='wide-success' label={'Upgrade'} onClick={()=>onClickUpgrade()} id="demo-upgrade-button" disabled={progress > 0 && progress < 1} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex h-1/3 flex-col justify-end space-y-0">
          <Fields.DescriptionField 
            label="Script" 
            placeholder="Script" 
            sublabel={""}
            disabled={true}
            value={activeDemo ? activeDemo.script : ''}
            fullheight={true}
          />
        </div>
      </div>
    </NewView>
  )
}

const OneOff = ({backEvent, setBackEvent, setBackEnabled}) => {
  return (
    <OneOffMain 
      backEvent={backEvent} 
      setBackEvent={setBackEvent} 
      setBackEnabled={setBackEnabled} 
    />
  );
}

export default OneOff;